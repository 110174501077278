import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/src/templates/default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Changelog`}</h1>
    <h2>{`Jun 12`}</h2>
    <ul>
      <li parentName="ul">{`ADDED: Support Dark Mode using `}<a parentName="li" {...{
          "href": "https://www.npmjs.com/package/react-toggle-dark-mode"
        }}><inlineCode parentName="a">{`react-toggle-dark-mode`}</inlineCode></a>{`. Thank you `}<a parentName="li" {...{
          "href": "https://github.com/samrose3"
        }}>{`Sam Rose`}</a>{` for the solution.`}</li>
      <li parentName="ul">{`ADDED: Project List on `}<a parentName="li" {...{
          "href": "/about"
        }}>{`About`}</a>{` page`}</li>
      <li parentName="ul">{`ADDED: Newsletter subscription form at footer! Sign up for bi-monthly digest!`}</li>
    </ul>
    <h2>{`May 21`}</h2>
    <ul>
      <li parentName="ul">{`ADDED: `}<a parentName="li" {...{
          "href": "/changelog"
        }}>{`Changelog`}</a>{` Page`}</li>
      <li parentName="ul">{`ADDED: New cover photo and project list on `}<a parentName="li" {...{
          "href": "/about"
        }}>{`About`}</a>{` page`}</li>
      <li parentName="ul">{`FIXED: `}<a parentName="li" {...{
          "href": "/tags"
        }}>{`Tags`}</a>{` link on Nav Bar using React hooks`}</li>
    </ul>
    <h2>{`May 20`}</h2>
    <ul>
      <li parentName="ul">{`FIXED: Today is Global Accessibility Awareness Day, fixed secondary color contrast issue, bumping accessibility score on Lighthouse to 100`}</li>
      <li parentName="ul">{`FIXED: Lighthouse performance score also went up from 65 to 87 by fixing `}<inlineCode parentName="li">{`gatsby-ssr.js`}</inlineCode>{` for CLR, reduce Prismic image size, set font face to swap, and set image loading to lazy`}</li>
    </ul>
    <h2>{`May 15`}</h2>
    <ul>
      <li parentName="ul">{`ADDED: Legacy `}<a parentName="li" {...{
          "href": "/posts/ideas-generator-for-designers-by-mit/"
        }}>{`blog post`}</a>{` from archive RSS site`}</li>
    </ul>
    <h2>{`May 14`}</h2>
    <ul>
      <li parentName="ul">{`CHANGED: `}<a parentName="li" {...{
          "href": "/guides"
        }}>{`Guides`}</a>{` page image hover effect, adjustment on the layout grid`}</li>
      <li parentName="ul">{`CHANGED: All posts' time stamp changed to from now`}</li>
    </ul>
    <h2>{`May 9`}</h2>
    <ul>
      <li parentName="ul">{`FIXED: Negative margin on `}<a parentName="li" {...{
          "href": "/"
        }}>{`Index`}</a>{` categories sidebar`}</li>
    </ul>
    <h2>{`May 6`}</h2>
    <ul>
      <li parentName="ul">{`ADDED: `}<a parentName="li" {...{
          "href": "/rss.xml"
        }}>{`RSS Feed`}</a>{` for all MDX blog posts`}</li>
      <li parentName="ul">{`ADDED: Sitemap plugin`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      